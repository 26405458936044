<template>
  <div id="slider-container">
    <div id="arrows">
      <div id="left-arrow" @click="changeSlide(-1)"></div>
      <div id="right-arrow" @click="changeSlide(+1)"></div>
    </div>
    <div id="carousel-container">
      <div
        class="carousel-item"
        :class="{
          active: slideId === 0,
          fourth: slideId === 1,
          third: slideId === 2,
          second: slideId === 3,
        }"
        @click="onClick(0)"
      >
        <img src="~@/assets/370.png" alt="" />
      </div>
      <div
        class="carousel-item"
        :class="{
          active: slideId === 1,
          fourth: slideId === 2,
          third: slideId === 3,
          second: slideId === 0,
        }"
        @click="onClick(1)"
      >
        <img src="~@/assets/400.png" alt="" />
      </div>
      <div
        class="carousel-item"
        :class="{
          active: slideId === 2,
          fourth: slideId === 3,
          third: slideId === 0,
          second: slideId === 1,
        }"
        @click="onClick(2)"
      >
        <img src="~@/assets/410.png" alt="" />
      </div>
      <div
        class="carousel-item"
        :class="{
          active: slideId === 3,
          fourth: slideId === 0,
          third: slideId === 1,
          second: slideId === 2,
        }"
        @click="onClick(3)"
      >
        <img src="~@/assets/420.png" alt="" />
      </div>
    </div>
    <div class="slider-inner">
      <div id="slider-content">
        <h2 id="slide-title">
          {{ $t("a370-title") }} <br /><span class="corsivo">{{
            $t("a370-subtitle")
          }}</span
          ><router-link to="a370"
            ><button>{{ $t("btn-learn-more") }}</button></router-link
          >
        </h2>
        <span class="hide" data-slide-title="1"
          >{{ $t("a400-title") }} <br /><span class="corsivo">{{
            $t("a400-subtitle")
          }}</span
          ><router-link to="a400"
            ><button>{{ $t("btn-learn-more") }}</button></router-link
          ></span
        >
        <span class="hide" data-slide-title="2"
          >{{ $t("a410-title") }} <br /><span class="corsivo">{{
            $t("a410-subtitle")
          }}</span
          ><router-link to="a410"
            ><button>{{ $t("btn-learn-more") }}</button></router-link
          ></span
        >
        <span class="hide" data-slide-title="3"
          >{{ $t("a420-title") }} <br /><span class="corsivo">{{
            $t("a420-subtitle")
          }}</span
          ><router-link to="a420"
            ><button>{{ $t("btn-learn-more") }}</button></router-link
          ></span
        >
        <span class="hide" data-slide-title="0"
          >{{ $t("a370-title") }} <br /><span class="corsivo">{{
            $t("a370-subtitle")
          }}</span
          ><router-link to="a370"
            ><button>{{ $t("btn-learn-more") }}</button></router-link
          ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-env jquery */
import { TweenMax } from "gsap";
export default {
  name: "ThetaCarousel",
  props: {},
  data() {
    return { slideId: 0 };
  },
  methods: {
    onClick(slideId) {
      if (this.slideId !== slideId) {
        this.slideId = slideId;
        var isAnimating = false;
        if (!isAnimating) {
          isAnimating = true;

          var slideTitleEl = document.getElementById("slide-title");

          var nextSlideTitle = document.querySelectorAll(
            '[data-slide-title="' + slideId + '"]'
          )[0].innerHTML;

          TweenMax.fromTo(
            slideTitleEl,
            0.2,
            {
              autoAlpha: 1,
              filter: "blur(0px)",
              y: 0,
            },
            {
              autoAlpha: 0,
              filter: "blur(30px)",
              y: 20,
              ease: "Expo.easeIn",
              onComplete: function onComplete() {
                slideTitleEl.innerHTML = nextSlideTitle;

                TweenMax.to(slideTitleEl, 0.6, {
                  autoAlpha: 1,
                  filter: "blur(0px)",
                  y: 0,
                });
              },
            }
          );
        }
      }
    },
    changeSlide(value) {
      const container = $("#carousel-container");
      if (value === -1) {
        switch (this.slideId) {
          case 0:
            this.onClick(3);

            container.theta_carousel("moveTo", 3);

            break;
          case 1:
            this.onClick(0);
            container.theta_carousel("moveTo", 0);

            break;

          case 2:
            this.onClick(1);
            container.theta_carousel("moveTo", 1);

            break;
          default:
            this.onClick(2);
            container.theta_carousel("moveTo", 2);

            break;
        }
      } else {
        switch (this.slideId) {
          case 0:
            this.onClick(1);
            container.theta_carousel("moveTo", 1);

            break;
          case 1:
            this.onClick(2);
            container.theta_carousel("moveTo", 2);

            break;

          case 2:
            this.onClick(3);
            container.theta_carousel("moveTo", 3);

            break;
          default:
            this.onClick(0);
            container.theta_carousel("moveTo", 0);

            break;
        }
      }
    },
  },
  mounted() {
    const container = $("#carousel-container");
    // insert theta-carousel logic here

    container.theta_carousel({
      filter: ".carousel-item",
      distance: 40,
      selectedIndex: 0,
      numberOfElementsToDisplayLeft: 0,
      designedForWidth: 1200,
      designedForHeight: 650,
      fallback: "never",
      path: {
        settings: {
          shiftY: 349,
          shiftZ: -1450,
          rotationAngleZY: 19,
          a: 800,
          b: 835,
          endless: true,
        },
        type: "ellipse",
      },
      perspective: 1000,
      rotationAnimationEasing: "easeInSine",
      rotationAnimationDuration: 300,
      sensitivity: 0.2,
      mousewheelEnabled: false,
      keyboardEnabled: false,
      gesturesEnabled: false,
      fadeAway: false,
      fadeAwayNumberOfConfigurableElements: 4,
      fadeAwayBezierPoints: {
        p1: {
          x: 0,
          y: 100,
        },
        p2: {
          x: -1,
          y: 58,
        },
        p3: {
          x: 30,
          y: 35,
        },
        p4: {
          x: 100,
          y: 12,
        },
      },
      sizeAdjustment: true,
      sizeAdjustmentNumberOfConfigurableElements: 4,
      sizeAdjustmentBezierPoints: {
        p1: {
          x: 0,
          y: 151,
        },
        p2: {
          x: 2,
          y: 110,
        },
        p3: {
          x: 5,
          y: 72,
        },
        p4: {
          x: 100,
          y: 72,
        },
      },
      popoutSelected: true,
      popoutSelectedShiftX: 75,
    });
  },
};
</script>

<style lang="scss">
.fourth {
  filter: brightness(0.5) blur(6px);
}
.third {
  filter: brightness(0.6) blur(4px);
}
.second {
  filter: brightness(0.8) blur(2px);
}
.active {
  filter: brightness(1) blur(0px);
}
</style>
